<template>
  <validation-observer v-slot="{ invalid }" slim>
    <form role="form" @submit="onFormSubmit" name="create-worker">
      <Civility v-bind:value.sync="form.civility" required labelAsColumn />

      <FirstName v-bind:value.sync="form.firstName" required labelAsColumn />

      <LastName v-bind:value.sync="form.lastName" required labelAsColumn />

      <DateInput v-bind:value.sync="form.dateOfBirth" :label="$t('profile.info.dateOfBirth')" required labelAsColumn />

      <TextField v-bind:value.sync="form.cityOfBirth" id="cityOfBirth" :label="$t('profile.info.cityOfBirth')" :placeholder="$t('profile.info.cityOfBirth_placeholder')" controlClass="control-label form-text m-b-none" required labelAsColumn />

      <Country v-bind:value.sync="form.countryCodeOfBirth" id="countryCodeOfBirth" :label="$t('profile.info.countryOfBirth')" :placeholder="$t('profile.info.countryOfBirth_select')" type="all" required labelAsColumn />

      <Nationality v-bind:value.sync="form.countryCodeOfNationality" type="all" required labelAsColumn />

      <button :disabled="invalid" class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">{{$t('eurofiscalis_workers.list.create-worker-button')}}</button>
    </form>
  </validation-observer>

</template>


<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import Civility from '../../../panels/input/Civility.vue';
import FirstName from '../../../panels/input/FirstName.vue';
import LastName from '../../../panels/input/LastName.vue';
import DateInput from '../../../panels/input/DateInput.vue';
import TextField from '@fwk-client/components/panels/input/TextField.vue';
import Nationality from '../../../panels/input/Nationality.vue';
import Country from '../../../panels/input/Country.vue';
import * as api from '@fwk-client/utils/api';
import { messagesTypes, MessageTypes } from '@fwk-client/store/types';
import * as Ladda from 'ladda';

@Component({
  components: { 
    Civility,
    FirstName,
    LastName,
    DateInput,
    TextField,
    Nationality,
    Country
  }
})
export default class Create extends Vue {

  @Prop({
    type: String,
    required: true
  }) readonly companyId!: string

  emptyForm:any = {
    civility: '',
    firstName: '',
    lastName: '',
    dateOfBirth: null,
    cityOfBirth: '',
    countryCodeOfBirth: '',
    countryCodeOfNationality: ''
  }

  form:any = {
    ...this.emptyForm
  };

  laddaSubmit:Ladda.LaddaButton|null = null;

  mounted() {
    var button:HTMLButtonElement|null = document.querySelector( 'form[name=create-worker] button.ladda-button' );
    this.laddaSubmit = Ladda.create(button!);
  }

  onFormSubmit(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    var input = {
      "civility" : this.form.civility,
      "firstName" : this.form.firstName,
      "lastName" : this.form.lastName,
      "dateOfBirth" : this.form.dateOfBirth,
      "cityOfBirth" : this.form.cityOfBirth,
      "countryCodeOfBirth" : this.form.countryCodeOfBirth,
      "countryCodeOfNationality" : this.form.countryCodeOfNationality,
      "companyId" : this.companyId
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.laddaSubmit!.start();
    
    api.postAPI('/api/eurofiscalis/workers/company/'+this.companyId+'/worker/create', input, options).then((response:any) => {
      if(response.created) {  
        this.$emit('worker-created', response.worker);

        // We reset the fields
        this.form = {
          ...this.emptyForm
        }
      }
      this.laddaSubmit!.stop();
    });
  }
  
}
</script>