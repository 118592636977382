<template>
  <validation-provider name="lastName" mode="eager" v-slot="{ errors, classes }" slim>
    <div :class="{...rowCSSClass, ...classes}">
      <label for="lastName" :class="labelCSSClass">{{ inputLabel }}</label>
      <div :class="fieldCSSClass">
        <input id="lastName" type="text" :placeholder="inputPlaceholder" class="form-control" v-model="input" :required="required"/>
        <span class="control-label form-text m-b-none" v-if="errors.length > 0">{{ errors[0] }}</span>
      </div>
    </div>
  </validation-provider>
</template>

<script lang="ts">
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericInput from '@fwk-client/components/mixins/GenericInput.vue';

@Component({
  components : {}
})
export default class LastName extends mixins<GenericInput<string>>(GenericInput) {

  inputLabel = "";
  inputPlaceholder = "";

  created() {
    this.inputLabel = (this.label && this.label != "") ? this.label : this.$i18n.t('profile.info.lastName') as string;
    this.inputPlaceholder = (this.placeholder && this.placeholder != "") ? this.placeholder : this.$i18n.t('profile.info.lastName_placeholder') as string;
  }
  
}
</script>