<template>
  <validation-provider :name="inputId" mode="eager" ref="input" :rules="computedValidationRules" v-slot="{ errors, classes }" slim>
    <div :class="{...rowCSSClass, ...classes}">
      <label :for="inputId" :class="labelCSSClass">{{inputLabel}}</label>
      <div :class="fieldCSSClass">
        <v-select ref="listNationalities" 
            :options="listNationalities" 
            :reduce="country => country.code" 
            label="label" 
            :id="inputId" 
            :placeholder="inputPlaceholder" 
            @keypress.enter.native.prevent="" 
            v-model="input">
          <template v-if="required" #search="{attributes, events}">
            <input
              class="vs__search"
              :required="!input"
              v-bind="attributes"
              v-on="events"
            />
          </template>
        </v-select>
        <span class="control-label form-text m-b-none" v-if="errors.length > 0">{{ errors[0] }}</span>
      </div>
    </div>
  </validation-provider>
</template>

<script lang="ts">
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericInput from '@fwk-client/components/mixins/GenericInput.vue';
import * as api from '@fwk-client/utils/api';

@Component({
  components : {}
})
export default class Nationality extends mixins<GenericInput<string>>(GenericInput) {

  @Prop({
    type: String,
    required: false,
    default: "EU"
  }) readonly type!: string

  inputLabel = "";
  inputPlaceholder = "";
  inputId = "nationality";

  listNationalities:any[] = [];

  created() {
    this.inputLabel = (this.label && this.label != "") ? this.label : this.$t('profile.info.countryOfNationality') as string
    this.inputPlaceholder = (this.placeholder && this.placeholder != "") ? this.placeholder : this.$t('profile.info.countryOfNationality_select') as string
    if(this.id) { this.inputId = this.id }

    this.updateListNationalities();
  }

  updateListNationalities() {
    // We need to get the list of available companies for the current logged in user
    var options:api.ApiVueOptions =  {
      app: this
    }

    var endpoint = "listEUNationalities";
    if(this.type != "EU") {
      endpoint = "listNationalities";
    }

    api.getAPI('/api/utils/' + endpoint, options).then((response:any) => {
      if(response.nationalities) {  
        this.listNationalities = response.nationalities;
        var _self = this;
        if(_self.input && _self.input != "") {
          // We get the value from countryCode
          var selected = this.listNationalities.find(function(element) {
            return element.code == _self.input;
          });
          if(selected) {
            // @ts-ignore
            _self.$refs.listNationalities.updateValue(selected);
          }
        }
      }
    });
  }

  @Watch('input')
  onInputChange() {
    // @ts-ignore
    this.$refs.input.validate();
  }

  @Watch('$store.state.languages.currentLanguageCode')
  onLanguageChange(to:any, from:any) {
    this.updateListNationalities();
  }
  
}
</script>