<template>
  <div class="ibox" ref="listWorkers">
    <div class="ibox-title">
      <h4>{{$t('eurofiscalis_workers.list.title')}}</h4>
      <div class="ibox-tools">
        <a class="collapse-link">
            <i class="fa fa-chevron-up"></i>
        </a>
      </div>
    </div>
    <div :class="'ibox-content p-md' + (listWorkersLoading ? ' sk-loading' : '')">

      <div v-if="listWorkersLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <b-table 
          outlined striped
          :items="workersProvider"
          :fields="workerFields"
          ref="listWorkersTable"
          show-empty
          :empty-text="$t('eurofiscalis_workers.list.no-worker')">
        <template v-slot:cell(select)="row">
          <p-check :id="'workersSelect_'+row.item._id" class="p-default p-curve p-primary" name="listWorkersSelected" color="success" :value="row.item._id" v-model="listWorkersSelected"></p-check>
        </template>
        <template v-slot:cell(options)="row">
          <a href="javascript:void(0)" @click="showWorkerDetailsModal(row.item)">{{$t('eurofiscalis_workers.list.show-worker-details-button')}}</a>
          /
          <a href="javascript:void(0)" @click="showWorkerUpdateModal(row.item)">{{$t('eurofiscalis_workers.list.update-worker-button')}}</a>
          /
          <a href="javascript:void(0)" @click="showWorkerRemoveConfirmModal(row.item)">{{$t('eurofiscalis_workers.list.remove-worker-button')}}</a>
        </template>
      </b-table>

      <button :disabled="!isAddWorkerAllowed" class="btn btn-primary" @click="showCreateWorkerModal()">{{$t('eurofiscalis_workers.list.add-worker-button')}}</button>

      <div v-if="listWorkersSelected.length > 0">
        Several workers selected.
      </div>

      <b-modal size="lg" ref="createWorkerModal" :title="$t('eurofiscalis_workers.list.create-worker-title')" hide-footer lazy>
        <CreateWorker v-on:worker-created="onWorkerCreated" :companyId="companyId" />
      </b-modal>

      <b-modal size="lg" ref="updateWorkerModal" :title="$t('eurofiscalis_workers.list.update-worker-title', [workerToUpdate.firstName + ' ' + workerToUpdate.lastName])" hide-footer lazy>
        <UpdateWorker v-on:worker-updated="onWorkerUpdated" :companyId="companyId" :worker="workerToUpdate" />
      </b-modal>

      <b-modal ref="removeWorkerModal" 
            hide-header
            @ok="removeWorker">
          <p v-html="$t('eurofiscalis_workers.list.confirm-remove-worker', ['<b>' + workerToRemove.firstName + ' ' + workerToRemove.lastName + '</b>'] )"></p>
          <p class="text-danger font-bold" v-html="$t('eurofiscalis_workers.list.confirm-remove-all-files')"></p>
        </b-modal>

      <b-modal size="xl" body-class="p-xs" ref="workerDetailsModal" :title="$t('eurofiscalis_workers.worker.details.details-title')" hide-footer lazy>
        <WorkerDetails :worker="workerToReview" />
      </b-modal>

    </div>
  </div>
</template>

<style scoped>
  td div.pretty {
    margin:0;
  }
</style>


<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import { mapState, mapGetters } from '@fwk-node-modules/vuex';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import * as api from '@fwk-client/utils/api';
import { messagesTypes, MessageTypes, languagesTypes } from '@fwk-client/store/types';
import { types as eurofiscalisTypes } from '../../../../store/eurofiscalis'
import PageTitle from '../../PageTitle.vue';
import CreateWorker from '../workers/Create.vue';
import UpdateWorker from '../workers/Update.vue';
import WorkerDetails from '../workers/Details.vue'
import * as Ladda from 'ladda';
import { enableIbox, disableIbox} from '../../../../theme/inspinia-jquery'; 

@Component({
  computed : {
   
  },
  components: { 
    PageTitle,
    CreateWorker,
    UpdateWorker,
    WorkerDetails
  }
})
export default class ListWorkers extends Vue {

  @Prop({
    type: String,
    required: true
  }) readonly companyId!: string

  emptyWorker:any = {
    firstName : "",
    lastName : ""
  };

  workerToReview:any = {...this.emptyWorker};
  workerToUpdate:any = {...this.emptyWorker};
  workerToRemove:any = {...this.emptyWorker};
  
  listWorkersLoading = false;
  isAddWorkerAllowed = false;

  listWorkersSelected = [];

  workerFields = [
      /*{
        key: "select",
        label: ""
      },*/
      {
        key: "firstName",
        label: ""
      },
      {
        key: "lastName",
        label: ""
      },
      {
        key: "formattedDateOfBirth",
        label: ""
      },
      {
        key: "formattedCreationDateTime",
        label: ""
      },
      {
        key: "_id",
        label: ""
      },
      {
        key: "options",
        label: ""
      }
    ];

  mounted() {
    this.updateTableLabels();

    enableIbox(this.$refs.listWorkers as HTMLElement);
  }

  updateTableLabels() {
    this.workerFields[0].label = this.$t('eurofiscalis_workers.worker.headers.first-name') as string;
    this.workerFields[1].label = this.$t('eurofiscalis_workers.worker.headers.last-name') as string;
    this.workerFields[2].label = this.$t('eurofiscalis_workers.worker.headers.birth-date') as string;
    this.workerFields[3].label = this.$t('eurofiscalis_workers.worker.headers.creation-date-time') as string;
    this.workerFields[4].label = this.$t('eurofiscalis_workers.worker.headers.id') as string;
    this.workerFields[5].label = this.$t('eurofiscalis_workers.worker.headers.options') as string; 
  }

  @Watch('$store.state.languages.currentLanguageCode')
  onLanguageChange(to:any, from:any) {
    this.updateTableLabels();
  }

  beforeDestroy() {
    disableIbox(this.$refs.listWorkers as HTMLElement);
  }

  workersProvider(ctx:any) {
    var options:api.ApiVueOptions =  {
      app: this
    }

    this.listWorkersLoading = true;

    return api.getAPI('/api/eurofiscalis/workers/company/'+this.companyId+'/workers', options).then((response:any) => {
      this.listWorkersLoading = false;
      if(response.workers) {  
        // We update the flag for permissions
        this.isAddWorkerAllowed = response.isAddWorkerAllowed;
        this.$store.commit('eurofiscalis/' + eurofiscalisTypes.mutations.WORKERS_SET_NUMBER_OF_WORKERS, response.workers.length);
        return response.workers;
      }
      else {
        return [];
      }
    })
  }

  showCreateWorkerModal() {
    // @ts-ignore
    this.$refs.createWorkerModal.show()
  }

  showWorkerUpdateModal(worker:any) {
    this.workerToUpdate = worker;
    // @ts-ignore
    this.$refs.updateWorkerModal.show()
  }

  showWorkerRemoveConfirmModal(worker:any) {
    this.workerToRemove = worker;
    // @ts-ignore
    this.$refs.removeWorkerModal.show()
  }

  removeWorker() {
    var options:api.ApiVueOptions =  {
      app: this
    }

    this.listWorkersLoading = true;
    
    api.getAPI('/api/eurofiscalis/workers/company/'+this.companyId+'/worker/'+this.workerToRemove._id+'/remove', options).then((response:any) => {
      if(response.removed) {
        // We update the list of workers
        // @ts-ignore
        this.$refs.listWorkersTable.refresh()
      }
      // We reset the role to remove
      this.workerToRemove = {...this.emptyWorker};

      this.listWorkersLoading = false;
    });
  }

  onWorkerCreated() {
    // @ts-ignore
    this.$refs.createWorkerModal.hide()
    // @ts-ignore
    this.$refs.listWorkersTable.refresh()
  }

  onWorkerUpdated() {
    // @ts-ignore
    this.$refs.listWorkersTable.refresh()
  }

  showWorkerDetailsModal(worker:any) {
    this.workerToReview = worker;
    // @ts-ignore
    this.$refs.workerDetailsModal.show()
  }
  
}
</script>