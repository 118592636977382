<template>
  <section>
    <div class="ibox">
      <div class="ibox-title no-borders">
        <h4>{{$t('eurofiscalis_workers.worker.details.details-title')}}</h4>
        <div class="ibox-tools">
          <a class="collapse-link">
              <i class="fa fa-chevron-up"></i>
          </a>
        </div>
      </div>
      <div class="ibox-content p-md">
        <div class="container">
          <div class="row">
            <div class="col-auto font-bold m-r">
              <div class="row">{{$t('profile.info.civility')}}</div>
              <div class="row">{{$t('profile.info.lastName')}}</div>
              <div class="row">{{$t('profile.info.firstName')}}</div>
            </div>
            <div class="col">
              <div class="row">{{$t('civilities.short.'+worker.civility)}}</div>
              <div class="row">{{worker.lastName}}</div>
              <div class="row">{{worker.firstName}}</div>
            </div>
            <div class="col-auto font-bold m-r">
              <div class="row">{{$t('profile.info.dateOfBirth')}}</div>
              <div class="row">{{$t('profile.info.cityOfBirth')}}</div>
              <div class="row">{{$t('profile.info.countryOfBirth')}}</div>
              <div class="row">{{$t('profile.info.countryOfNationality')}}</div>
            </div>
            <div class="col">
              <div class="row">{{worker.formattedDateOfBirth}}</div>
              <div class="row">{{worker.cityOfBirth}}</div>
              <div class="row">{{$t("COUNTRIES."+worker.countryCodeOfBirth.toUpperCase())}}</div>
              <div class="row">{{worker.formattedCountryCodeOfNationality}}</div>
            </div>
            <div class="col-auto font-bold m-r">
              <div class="row">ID</div>
              <div class="row">{{$t('creation-date-time')}}</div>
              <div class="row"></div>
            </div>
            <div class="col">
              <div class="row">{{worker._id}}</div>
              <div class="row">{{worker.formattedCreationDateTime}}</div>
              <div class="row"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ibox">
      <div class="ibox-title no-borders">
        <h4>{{$t('eurofiscalis_workers.worker.details.documents-title')}}</h4>
        <div class="ibox-tools">
          <a class="collapse-link">
              <i class="fa fa-chevron-up"></i>
          </a>
        </div>
      </div>
      <div :class="'ibox-content p-md' + (listWorkerDocumentsLoading ? ' sk-loading' : '')">

        <div v-if="listWorkerDocumentsLoading" class="sk-spinner sk-spinner-double-bounce">
            <div class="sk-double-bounce1"></div>
            <div class="sk-double-bounce2"></div>
        </div>

        <b-table 
          outlined striped
          :items="workerDocumentsProvider"
          :fields="workerDocumentFields"
          ref="listWorkerDocuments"
          show-empty
          empty-text="No document">
        <template v-slot:cell(select)="row">
          <p-check :id="'workerDocumentsSelect_'+row.item._id" class="p-default p-curve p-primary" name="listWorkerDocumentsSelected" color="success" :value="row.item._id" v-model="listWorkerDocumentsSelected"></p-check>
        </template>
        <template v-slot:cell(options)="row">
          <a :href="getWorkerDocumentURL(row.item)"><i class="fa fa-cloud-download"></i></a>
          /
          <a href="javascript:void(0)" @click="confirmRemoveWorkerDocument(row.item)"><i class="fa fa-trash-o"></i></a>
        </template>
        </b-table>

        <button class="btn btn-primary" @click="showAddWorkerDocumentModal()">{{$t('eurofiscalis_workers.worker.documents.add-document-button')}}</button>

        <b-modal size="xl" ref="uploadWorkerDocumentModal" :title="$t('eurofiscalis_workers.worker.documents.upload-worker-document-title')" hide-footer lazy>
          <UploadWorkerDocument v-on:worker-documents-uploaded="onWorkerDocumentsUploaded" :worker="worker" />
        </b-modal>

        <b-modal ref="removeWorkerDocumentModal" 
            hide-header
            @ok="removeWorkerDocument">
          {{ $t('eurofiscalis_workers.worker.documents.confirm-remove-document', [workerDocumentToRemove.fileName]) }}
        </b-modal>
      </div>
    </div>
  </section>
</template>


<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import * as api from '@fwk-client/utils/api';
import { messagesTypes, MessageTypes } from '@fwk-client/store/types';
import UploadWorkerDocument from './FileUpload.vue'
import * as Ladda from 'ladda';

@Component({
  components: { 
    UploadWorkerDocument
  }
})
export default class Details extends Vue {

  @Prop({
    type: Object,
    required: true
  }) readonly worker!: any

  listWorkerDocumentsLoading = false;

  listWorkerDocumentsSelected = [];

  readonly emptyWorkerDocument:any = {
    "fileName" : ""
  };

  workerDocumentToRemove:any = {...this.emptyWorkerDocument};

  workerDocumentFields = [
      /*{
        key: "select",
        label: ""
      },*/
      {
        key: "fileName",
        label: ""
      },
      {
        key: "category",
        label: "",
        formatter:(categoryCode:string) => {
          return this.getCategoryLabel(categoryCode);
        }
        
      },
      {
        key: "formattedCreationDateTime",
        label: ""
      },
      {
        key: "mime",
        label: "",
        formatter:(mime:string) => {
          return this.getMimeLabel(mime);
        }
      },
      {
        key: "_id",
        label: ""
      },
      {
        key: "options",
        label: ""
      }
    ];

  getCategoryLabel(categoryCode:string) {
    return this.$t('eurofiscalis_workers.worker.documents.categories.'+categoryCode) as string;
  }

  getMimeLabel(mime:string) {
    if(!mime) {
      return this.$t('eurofiscalis_workers.worker.documents.mime-unkown') as string;
    }
    return mime;
  }

  mounted() {
    this.updateTableLabels();
  }

  updateTableLabels() {
    this.workerDocumentFields[0].label = this.$t('eurofiscalis_workers.worker.documents.name') as string;
    this.workerDocumentFields[1].label = this.$t('eurofiscalis_workers.worker.documents.category') as string;
    this.workerDocumentFields[2].label = this.$t('eurofiscalis_workers.worker.documents.creation-date') as string;
    this.workerDocumentFields[3].label = this.$t('eurofiscalis_workers.worker.documents.format') as string;
    this.workerDocumentFields[4].label = this.$t('eurofiscalis_workers.worker.documents.id') as string;
    this.workerDocumentFields[5].label = this.$t('eurofiscalis_workers.worker.documents.options') as string; 
  }

  @Watch('$store.state.languages.currentLanguageCode')
  onLanguageChange(to:any, from:any) {
    this.updateTableLabels();
  }

  workerDocumentsProvider(ctx:any) {
    var options:api.ApiVueOptions =  {
      app: this
    }

    this.listWorkerDocumentsLoading = true;

    return api.getAPI('/api/eurofiscalis/workers/company/'+this.worker.company._id+'/worker/'+this.worker._id+'/documents', options).then((response:any) => {
      this.listWorkerDocumentsLoading = false;
      if(response.documents) {  
        return response.documents;
      }
      else {
        return [];
      }
    })
  }

  getWorkerDocumentURL(document:any) {
    var url = '/api/eurofiscalis/workers/company/'+this.worker.company._id+'/worker/'+this.worker._id+'/document/'+document._id+'/download';
    return url;
  }

  confirmRemoveWorkerDocument(document:any) {
    this.workerDocumentToRemove = document;
    // @ts-ignore
    this.$refs.removeWorkerDocumentModal.show()
  }

  removeWorkerDocument() {

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.listWorkerDocumentsLoading = true;
    
    api.getAPI('/api/eurofiscalis/workers/company/'+this.worker.company._id+'/worker/'+this.worker._id+'/document/'+this.workerDocumentToRemove._id+'/remove', options).then((response:any) => {
      if(response.removed) {
        // We update the list of worker documents
        // @ts-ignore
        this.$refs.listWorkerDocuments.refresh()
      }
      // We reset the role to remove
      this.workerDocumentToRemove = {...this.emptyWorkerDocument};

      this.listWorkerDocumentsLoading = false;
    });
  }

  showAddWorkerDocumentModal() {
    // @ts-ignore
    this.$refs.uploadWorkerDocumentModal.show()
  }

  onWorkerDocumentsUploaded() {
    // @ts-ignore
    this.$refs.listWorkerDocuments.refresh();
  }

  
}
</script>