<template>
  <validation-provider name="civility" mode="eager" v-slot="{ errors, classes }" slim>
    <div :class="{...rowCSSClass, ...classes}">
      <label for="civility" :class="labelCSSClass">{{inputLabel}}</label>
      <div :class="fieldCSSClass">
        <select id="civility" class="form-control" v-model="input" :required="required">
          <option value="">{{inputPlaceholder}}</option>
          <option v-for="(civilityCode, index) in civilities" :key="index" :value="civilityCode">{{$t('civilities.short.'+civilityCode)}}</option>
        </select>
        <span class="control-label form-text m-b-none" v-if="errors.length > 0">{{ errors[0] }}</span>
      </div>
    </div>
  </validation-provider>
</template>

<script lang="ts">
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericInput from '@fwk-client/components/mixins/GenericInput.vue';

@Component({
  components : {}
})
export default class Civility extends mixins<GenericInput<string>>(GenericInput) {

  civilities = ["MR","MRS"];

  inputLabel = "";
  inputPlaceholder = "";

  created() {
    this.inputLabel = (this.label && this.label != "") ? this.label : this.$t('profile.info.civility') as string
    this.inputPlaceholder = (this.placeholder && this.placeholder != "") ? this.placeholder : this.$t('profile.info.civility_select') as string
  }
  
}
</script>