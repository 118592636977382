<template>
  <div>
    <form enctype="multipart/form-data" v-on:submit.prevent="onSubmit">
      <div class="dropbox">
        <input type="file" multiple :name="uploadFieldName" :disabled="isSaving" @change="filesChange($event.target.files); fileCount = $event.target.files.length"
          :accept="acceptedTypes" class="input-file">
          <p v-if="isInitial" v-html="$t('eurofiscalis_workers.worker.documents.upload-field-initial')"></p>
          <p v-if="hasFiles" v-html="$t('eurofiscalis_workers.worker.documents.upload-field-files')"></p>
          <p v-if="isSaving" v-html="$t('eurofiscalis_workers.worker.documents.upload-field-saving', [fileCount])"></p>
      </div>

      <validation-observer v-if="filesToUpload.length > 0" v-slot="{ invalid }" slim>

        <b-table 
          outlined striped
          :items="filesToUpload"
          :fields="filesToUploadFields"
          ref="filesToUpload"
          show-empty
          empty-text="No document"
          class="files-to-upload">
        <template v-slot:cell(category)="row">
          <validation-provider name="category" mode="eager" v-slot="{ errors, classes }" slim>
            <div :class="{...classes}">
              <select :id="'category_'+row.index" class="form-control" v-model="filesToUploadCategories[row.index]" required>
                <option value="">{{$t('eurofiscalis_workers.worker.documents.category_select')}}</option>
                <option v-for="(categoryCode, index) in categories" :key="index" :value="categoryCode">{{$t('eurofiscalis_workers.worker.documents.categories.'+categoryCode)}}</option>
              </select>
              <span class="control-label form-text m-b-none" v-if="errors.length > 0">{{ errors[0] }}</span>
            </div>
          </validation-provider>
        </template>
        <template v-slot:cell(options)="row">
          <a href="javascript:void(0)" @click="removeDocument(row.item, row.index)"><i class="fa fa-window-close-o"></i></a>
        </template>
        </b-table>

        <button :disabled="invalid" type="button" class="btn btn-primary" @click="uploadDocuments()">{{$t('eurofiscalis_workers.worker.documents.upload-documents-button')}}</button>
      </validation-observer>
    </form>    
  </div>
</template>

<style>
  .dropbox {
    outline: 1px dashed #ffffff; /* the dash box */
    outline-offset: -10px;
    background: #1ab394;
    position: relative;
    cursor: pointer;
    color: #ffffff;
  }

  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
  }

  .dropbox:hover {
    background: #18a689; /* when mouse over to the drop zone, change color */
  }

  .dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 20px 0;
  }

  table.files-to-upload>tbody>tr>td {
    line-height: 33px;
  }

  table.files-to-upload .options a {
    font-size: 1.3em;
    margin-right: 10px;
  }
</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import * as api from '@fwk-client/utils/api';

const STATUS_INITIAL = 0;
const STATUS_HAS_FILES = 1;
const STATUS_SAVING = 2;
const STATUS_SUCCESS = 3;
const STATUS_FAILED = 4;

@Component({
  components: {},
  computed: {}
})
export default class FileUpload extends Vue {

  @Prop({
    type: Object,
    required: true
  }) readonly worker!: any

  filesToUpload:Array<any> = [];
  filesToUploadCategories:Array<any> = [];

  currentStatus:number = STATUS_INITIAL;
  acceptedTypes:string = "*/*";

  uploadFieldName:string = "files";
  categoryFieldName:string = "categories";

  categories = ["WORK_CONTRACT", "ATTESTATION_DETACHEMENT", "SALARY", "SALARY_PROOF", "A1", "REGISTER_WORKING_TIME", "OTHER"];
  
  get isInitial() { return this.currentStatus === STATUS_INITIAL; };
  get hasFiles() { return this.currentStatus === STATUS_HAS_FILES; };
  get isSaving() { return this.currentStatus === STATUS_SAVING; };

  reset() {
    // reset form to initial state
    this.currentStatus = STATUS_INITIAL;
    this.filesToUpload = [];
    this.filesToUploadCategories = [];
  };

  filesChange(fileList:Array<any>) {
    
    if (!fileList.length) return;

    // We check to add only new files
    var filesToAdd = Array.from(fileList).filter((file:any) => {
      for(var existingFile of this.filesToUpload) {
        if(existingFile.name == file.name && existingFile.size == file.size) {
          return false;
        }
      }
      return true;
    })

    this.filesToUpload = [
      ...this.filesToUpload,
      ...filesToAdd
    ]

    this.filesToUploadCategories = [
      ...this.filesToUploadCategories,
      ...Array(fileList.length).fill("")
    ]

    this.currentStatus = STATUS_HAS_FILES;
  };

  mounted() {
    this.updateTableLabels();

    this.reset();
  };

  updateTableLabels() {
    this.filesToUploadFields[0].label = this.$t('eurofiscalis_workers.worker.documents.name') as string;
    this.filesToUploadFields[1].label = this.$t('eurofiscalis_workers.worker.documents.size') as string;
    this.filesToUploadFields[2].label = this.$t('eurofiscalis_workers.worker.documents.category') as string;
    this.filesToUploadFields[3].label = this.$t('eurofiscalis_workers.worker.documents.options') as string;
  }

  @Watch('$store.state.languages.currentLanguageCode')
  onLanguageChange(to:any, from:any) {
    this.updateTableLabels();
  }

  filesToUploadFields = [
      {
        key: "name",
        label: ""
      },
      {
        key: "size",
        label: "",
        formatter: function(value:string, key:string, item:any) {
          var size = parseInt(value);
          if(size < 1000) {
            return size + 'o';
          }
          if(size >= 1000 && size < 1000000) {
            return Math.trunc(size/1000)+'Ko'
          }
          if(size >= 1000000) {
            return Math.trunc(size/1000000)+'Mo'
          }
          return '';
        }
      },
      {
        key: "category",
        label: ""
      },
      {
        key: "options",
        label: "",
        class: "options"
      }
    ];

  removeDocument(file:any, index:number) {
    this.filesToUpload.splice(index,1);
    this.filesToUploadCategories.splice(index,1);
  }

  onSubmit(evt:Event) { evt.preventDefault(); }

  uploadDocuments() {

    const formData = new FormData();

    // append the files to FormData
    Array
      .from(Array(this.filesToUpload.length).keys())
      .map(x => {
        formData.append(this.uploadFieldName, this.filesToUpload[x], this.filesToUpload[x].name);
        formData.append(this.categoryFieldName, this.filesToUploadCategories[x]);
      });

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.currentStatus = STATUS_SAVING;

    api.postAPIFormData('/api/eurofiscalis/workers/company/'+this.worker.company._id+'/worker/'+this.worker._id+'/documents/upload', formData, options).then((response:any) => {
      if(response.uploaded) {
        this.$emit('worker-documents-uploaded', response.files);
        
        this.reset();
      }
    });
  }

}
</script>